<template>
  <div class="popover-wrapper">
    <Popover :show="showUserPopover" direction="bot" anchor="right" @close="showUserPopover = false">
      <div class="popover-box">
        <div class="menu-section">
          <div v-for="item in menuHeaderItems" :key="item.label">
            <PageLink :to="item.to" class="page-link">
              <div class="menu-item">{{ item.label }}</div>
            </PageLink>
          </div>
        </div>

        <hr />

        <div class="menu-section">
          <div v-for="[company_id, { dba, entity_name, origin }] in companyEntries" :key="company_id" class="menu-item">
            <PageLink :to="`/dashboard?co=${company_id}`" class="page-link">
              <Stack gap="xs" class="company-link" :class="{ selected: selectedCompanyId === company_id }">
                <strong>{{ dba || entity_name }}</strong>
                <span class="text-xs" style="font-weight: 300">{{ origin === 'peo' ? 'PEO' : 'Payroll' }}</span>
              </Stack>
            </PageLink>
          </div>
        </div>

        <hr />

        <div class="menu-section">
          <PageLink class="page-link" to="/logout">
            <div class="menu-item">{{ $t('Log out') }}</div>
          </PageLink>
        </div>
      </div>
    </Popover>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { Popover, Stack } from '@justworkshr/alma'
import { t } from '@/i18n.js'
import { viewer } from '@/viewer.js'
import PageLink from '@/components/PageLink.vue'

const showUserPopover = defineModel({ type: Boolean })

const menuHeaderItems = computed(() => [
  { label: t('Account settings'), to: '/account/settings' },
  { label: t('Internal controls'), to: '/internal' },
])

const companyEntries = computed(
  () => Object.entries(viewer.value.companies).sort((a, b) => a[1].entity_name.localeCompare(b[1].entity_name)) || []
)
const selectedCompanyId = computed(() => viewer.value.activeCompany.company_id)
</script>

<style scoped>
.popover-wrapper :deep(.popover) {
  z-index: 2;
  top: 40px;
}

.popover-wrapper .popover-box {
  width: 371px;
}

.popover-wrapper .popover-box hr {
  margin: 0;
  color: var(--peo-color-line);
}

.popover-wrapper .popover-box .menu-section {
  margin: 12px;
  max-height: 550px;
  overflow-y: auto;
}

.popover-wrapper .popover-box .menu-item {
  padding: 8px 12px;
  border-radius: var(--radius-xs);
  color: var(--peo-color-menu-item);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    transition: 0.25s;
    background-color: var(--peo-color-menu-item-hover);
  }
}

.popover-wrapper .popover-box :deep(.page-link) {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  padding-bottom: 3px;
}

.popover-wrapper .popover-box .company-link {
  color: var(--peo-color-menu-company-item);
}

.popover-wrapper .popover-box .company-link.selected {
  color: var(--peo-color-menu-item);
}
</style>
