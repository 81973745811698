import { computed } from 'vue'
import { t } from '@/i18n'
import { viewer, viewerCan } from '@/viewer.js'
import useNavPermissions from '@/use/useNavPermissions.js'

// TODO: Missing nav items

// Vendor users
// vendor_upsell_group: 'Vendor upsell group'
// - 'Unlock all Justworks features!'

// vendor_company_group: 'Company'
// - 'Documents'
// - 'Settings'

// Unknown
// you_group: 'You'
// - 'Onboarding checklist'
// - 'Timecards'
// - 'Expenses'

export default function usePeoNavItems() {
  const sageIntegrationHost = import.meta.env.VITE_APP_SAGE_INTEGRATION_BASE_URL

  const { showNavPayments, showNavManage, showNavAnalytics, showEOY } = useNavPermissions()

  const companyId = computed(() => viewer.value?.activeCompany.company_id)
  const sageMemberId = computed(() => viewer.value?.activeMembership.member.sage_member_id)
  const benefitsId = computed(() => viewer.value?.activeMembership.member.benefits_id)
  const benefitsSSOLink = computed(() => `${sageIntegrationHost}admin?target_member_id=${benefitsId.value}`)

  return computed(() => [
    {
      groupName: t('Unlock all Justworks features!'),
      standaloneContent: true,
      visible: false,
      key: 'vendor_company',
      navItems: [],
    },
    {
      groupName: t('End of Year Task List'),
      standaloneContent: true,
      visible: showEOY.value,
      path: '/eoy',
      key: 'eoy',
    },
    {
      groupName: t('Payments'),
      key: 'payments',
      visible: showNavPayments.value,
      navItems: [
        {
          path: '',
          altPath: `${import.meta.env.VITE_APP_EXPENSES_BASE_URL}/ccid/${companyId.value}/review`,
          displayText: t('Expenses'),
          badge: {
            type: 'new',
            text: t('New'),
          },
        },
        {
          path: '/cf/pay-transactions',
          displayText: t('Transactions'),
        },
        {
          path: '/payments/payments-center',
          displayText: t('Payments center'),
        },
        {
          path: `/company/${companyId.value}/timecards`,
          displayText: t('Timecards'),
        },
        {
          path: `/payments/${companyId.value}/payrolls`,
          displayText: t('Invoices'),
        },
        {
          path: '/tax_credits',
          displayText: t('R&D tax credit'),
        },
      ],
    },
    {
      groupName: t('HR'),
      key: 'hr',
      visible: true,
      navItems: [
        {
          path: `/company/${companyId.value}/benefits`,
          altPath: viewerCan('manage_company_benefits') && sageMemberId.value ? benefitsSSOLink.value : null,
          displayText: t('Benefits'),
        },
        {
          path: '/bulk_actions',
          displayText: t('Bulk updates'),
        },
        {
          path: `/company/${companyId.value}/time-off`,
          displayText: t('Time off'),
        },
        {
          path: '/admin/trainings',
          displayText: t('Training'),
        },
        {
          path: `/company/${companyId.value}/documents`,
          displayText: t('Documents'),
        },
        {
          path: '/help/hr_resources',
          displayText: t('Resources'),
        },
      ],
    },
    {
      groupName: t('Manage'),
      key: 'manage',
      visible: showNavManage.value,
      navItems: [
        {
          path: `/manage/${companyId.value}/employees`,
          displayText: t('Employees'),
        },
        {
          path: `/manage/${companyId.value}/contractors`,
          displayText: t('Contractors'),
        },
        {
          path: '/members/vendors',
          displayText: t('Vendors'),
        },
        {
          path: `/manage/${companyId.value}/third-party-admins`,
          displayText: t('Third party'),
        },
      ],
    },
    {
      groupName: t('Analytics'),
      key: 'analytics',
      visible: showNavAnalytics.value,
      navItems: [
        {
          path: '/cf/analytics/insights',
          displayText: t('Insights'),
          badge: {
            type: 'new',
            text: t('New'),
          },
        },
        {
          path: '/cf/analytics/reporting',
          displayText: t('Reporting'),
        },
      ],
    },
    {
      groupName: t('Company'),
      key: 'company',
      visible: true,
      navItems: [
        {
          path: `/company/${companyId.value}/directory`,
          displayText: t('Directory'),
        },
        {
          path: '/directory/org_chart',
          displayText: t('Org chart'),
        },
        {
          path: '/company/calendar',
          displayText: t('Calendar'),
        },
        {
          path: '/cf/integration-marketplace',
          displayText: t('Integrations'),
        },
        {
          path: `company/${companyId.value}/settings`,
          displayText: t('Settings'),
        },
        {
          path: '/company/custom_member_fields',
          displayText: t('Custom fields'),
        },
        {
          path: '/permissions',
          displayText: t('Permissions'),
        },
        {
          path: '/benefits/company_benefits_overviews/index?source=sidenav',
          displayText: t('Benefits overview'),
        },
      ],
    },
    {
      groupName: t('You'),
      key: 'you',
      visible: true,
      navItems: [
        {
          path: '/vouchers',
          displayText: t('Paystubs'),
        },
        {
          path: '/account/time-off',
          displayText: t('Time off'),
        },
        {
          path: '/my_benefits',
          displayText: t('Benefits'),
        },
        {
          path: '/account/documents',
          displayText: t('Documents'),
        },
        {
          path: '/training',
          displayText: t('Training'),
        },
        {
          path: '/account/settings',
          displayText: t('Account settings'),
        },
      ],
    },
    {
      groupName: '',
      visible: true,
      navItems: [
        {
          path: '/refer',
          displayText: t('Refer & Earn'),
          badge: {
            type: 'new',
            text: t('New'),
          },
        },
      ],
    },
  ])
}
