<template>
  <div>
    <GhostBanner v-if="viewer?.user_id.endsWith('admin-user')" />
    <Transition name="fade" mode="out-in">
      <component :is="isActiveCompanyPEO ? DefaultPEO : DefaultPayroll" :key="viewer?.activeCompany?.company_id" />
    </Transition>
  </div>
</template>

<script setup>
import { defineAsyncComponent, watch } from 'vue'
import { viewer, isActiveCompanyPEO } from '@/viewer.js'
import DefaultPEO from './internals/layout-delegates/DefaultPEO.vue'
import DefaultPayroll from './internals/layout-delegates/DefaultPayroll.vue'

const GhostBanner = defineAsyncComponent(() => import('./internals/components/GhostBanner.vue'))

watch(
  isActiveCompanyPEO,
  (isPeo) => {
    if (!isPeo) return
    forceLightMode()
  },
  { immediate: true }
)

function forceLightMode() {
  localStorage.setItem('appearance', 'light')
  document.documentElement.classList.remove('dark')
}
</script>
