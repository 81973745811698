/** This file contains a map of payroll route names to their corresponding PEO URLs. */
export const payrollRouteNameToPEOURLMap = new Map([
  ['account-documents', '/documents'],
  ['account-settings', '/account_settings'],
  ['account-settings', '/account_settings'],
  ['account-time-off', '/employee_pto'],
  ['company-calendar', '/calendar'],
  ['company-company_id-benefits', '/benefits/view'],
  ['company-company_id-directory', '/directory'],
  ['company-company_id-documents', '/admin/documents'],
  ['company-company_id-invite-new', '/cf/invitation-center#employees'],
  ['company-company_id-settings', '/company'],
  ['company-company_id-time-off', '/pto/requests'],
  ['company-company_id-timecards', '/hourly'],
  ['manage-company_id-contractors', '/members/contractors'],
  ['manage-company_id-third-party-admins', '/cf/firms'],
  ['member-member_id', '/members/employees'],
  ['payments-company_id-payrolls', '/invoices'],
  ['payments-payments-center', '/masspay'],
])

/** This file contains a map of non existing payroll paths to their corresponding PEO URLs. */
export const payrollURLMap = new Map([
  ['/admin/trainings', ['manage_employees']],
  ['/benefits/company_benefits_overviews/index?source=sidenav', ['manage_company_benefits']],
  ['/bulk_actions', ['manage_employees']],
  ['/cf/analytics/insights', ['view_invoices_reporting']],
  ['/cf/analytics/reporting', ['view_invoices_reporting']],
  ['/cf/integration-marketplace', ['edit_company_settings']],
  ['/cf/pay-transactions', ['make_payments']],
  ['/company/custom_member_fields', ['manage_employees']],
  ['/directory/org_chart', []],
  ['/eoy', ['manage_employees']],
  ['/help', []],
  ['/help/hr_resources', []],
  ['/internal', []],
  ['/members/vendors', ['manage_employees']],
  ['/my_benefits', []],
  ['/permissions', ['edit_employee_permissions']],
  ['/refer', []],
  ['/training', []],
  ['/vouchers', ['paystubs']],
])

export function getPeoRedirectURL(to) {
  const { name, path, redirectedFrom } = to

  // Check if the route name exists in the map
  if (payrollRouteNameToPEOURLMap.has(name)) {
    return payrollRouteNameToPEOURLMap.get(name)
  }

  // Check if the redirected route name exists in the map
  if (redirectedFrom?.name && payrollRouteNameToPEOURLMap.has(redirectedFrom.name)) {
    return payrollRouteNameToPEOURLMap.get(redirectedFrom.name)
  }

  // Check if the path exists in the set of non-existing payroll paths
  if (payrollURLMap.has(path)) {
    return path
  }

  // Default redirect URL
  return '/dashboard'
}

export function getPeoNavPermissions(to) {
  const { path } = to
  return payrollURLMap.has(path) ? payrollURLMap.get(path) : undefined
}
