<template>
  <div style="position: relative">
    <button class="btn-icon help-button" @click="showHelpMenu = true">
      <Row style="cursor: pointer; align-items: center" gap="xs" :class="{ focused: showHelpMenu }">
        <HelpChatBubble class="chat-bubble" height="28" width="26" />
        <span class="help-text">{{ $t('Help') }}</span>
        <span class="down-triangle">&#9660;</span>
      </Row>
    </button>

    <div class="popover-wrapper">
      <Popover :show="showHelpMenu" direction="bot" anchor="right" @close="showHelpMenu = false">
        <Stack gap="sm">
          <span class="h4">{{ $t('Need help?') }}</span>
          <small>{{ $t('Quickly find answers to your product, compliance, and HR questions.') }}</small>
          <PageLink to="/help" class="button btn-block" style="background-color: var(--peo-color-accent)">
            {{ $t('Browse Resources') }}
          </PageLink>
        </Stack>
      </Popover>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Popover, Row, Stack } from '@justworkshr/alma'
import PageLink from '@/components/PageLink.vue'
import HelpChatBubble from './assets/header/HelpChatBubble.vue'

const showHelpMenu = ref(false)
</script>

<style scoped>
.chat-bubble {
  color: var(--peo-color-header-chat-bubble);
  display: block;
  margin: auto;
}

.down-triangle {
  height: 16px;
  width: 16px;
  color: var(--peo-color-line);
  transform: scale(1, 0.5);
}

.help-text {
  color: var(--peo-color-header-chat-text);
  font-weight: 600;
  font-size: 13px;
}

.help-button:hover .chat-bubble,
.help-button:hover .down-triangle {
  color: var(--peo-color-header-chat-bubble-hover);
}

.help-button > .focused :is(.chat-bubble, .down-triangle, .help-text) {
  color: var(--peo-color-header-chat-bubble-active);
}

.help-text,
.down-triangle {
  display: block;

  @media (--tablet) {
    display: none;
  }

  @media (--desktop) {
    display: block;
  }
}

.popover-wrapper :deep(.popover) {
  width: 258px;
  padding: var(--space-md);
  z-index: 2;
  top: 40px;
}
</style>
