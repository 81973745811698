import { computed } from 'vue'
import { viewer, viewerCan } from '@/viewer.js'

export default function useNavPermissions() {
  const showNavPayments = computed(() =>
    viewerCan('make_payments', 'pay_contractors_and_vendors', 'view_invoices_reporting', 'manage_all_timecards')
  )

  const showNavManage = computed(() =>
    viewerCan('manage_employees', 'view_all_employee_information', 'manage_all_paid_time_off', 'manage_paid_time_off')
  )

  const showEOY = computed(() => viewerCan('manage_employees'))
  const showNavAnalytics = computed(() => viewerCan('view_invoices_reporting'))
  const showNavCompany = computed(() => viewer.value.activeMembership.member.member_status !== 'terminated')
  const showNavMember = computed(() => viewer.value.ghost_type === '')

  return {
    showEOY,
    showNavAnalytics,
    showNavCompany,
    showNavManage,
    showNavMember,
    showNavPayments,
  }
}
