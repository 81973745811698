<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      d="M10,18a8,8,0,1,1,6.8-3.8l.65,2.44a1,1,0,0,1-1.22,1.22l-2.65-.71A8,8,0,0,1,10,18ZM10,4a6,6,0,1,0,3,11.21,1,1,0,0,1,.75-.1l1.36.36-.32-1.2a1,1,0,0,1,.15-.83A6,6,0,0,0,10,4Z"
      fill="currentColor"
    />
    <g>
      <path
        d="M10,12a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1,.91.91,0,0,0,1-1,.91.91,0,0,0-1-1A.92.92,0,0,0,9,8,1,1,0,0,1,8,9,1,1,0,0,1,7,8a2.92,2.92,0,0,1,3-3,2.92,2.92,0,0,1,3,3,2.82,2.82,0,0,1-2,2.83V11A1,1,0,0,1,10,12Z"
        fill="currentColor"
      />
      <path d="M10,13a1,1,0,1,0,1,1,1,1,0,0,0-1-1Z" fill="currentColor" />
    </g>
  </svg>
</template>
