<template>
  <div style="position: relative">
    <Avatar
      :image-url="member?.profile_picture_url"
      :first-name="member?.preferred_name || member?.first_name"
      :last-name="member?.last_name"
      :focused="showUserPopover"
      @click="showUserPopover = true"
    />

    <HeaderMenuPopover v-model="showUserPopover" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { viewer } from '@/viewer.js'
import Avatar from './Avatar.vue'
import HeaderMenuPopover from './HeaderMenuPopover.vue'

const showUserPopover = ref(false)
const member = computed(() => viewer.value.activeMembership && viewer.value.activeMembership.member)
</script>
