<template>
  <header>
    <template v-if="mobile">
      <Row style="justify-content: space-between; align-items: center">
        <IconList width="28" height="28" @click="mainNavOpen = true" />

        <PageLink to="/dashboard">
          <LogoJustworks height="16" style="display: block; margin: auto" aria-label="Justworks — Dashboard" />
        </PageLink>

        <HeaderAvatarMenu />
      </Row>

      <Drawer :open="mainNavOpen" class="nav-drawer" padding="md" @close="mainNavOpen = false">
        <PEOMainNav @close="mainNavOpen = false" />
      </Drawer>
    </template>

    <template v-else-if="tablet">
      <Row class="header-row" gap="2xl">
        <PageLink to="/dashboard" style="text-decoration: none">
          {{ viewer.activeCompany.dba || viewer.activeCompany.entity_name }}
        </PageLink>

        <FlexSpace />

        <HeaderHelpMenu />
        <HeaderAvatarMenu />
      </Row>
    </template>
  </header>
</template>

<script setup>
import { ref } from 'vue'
import { FlexSpace, Row, Drawer } from '@justworkshr/alma'
import { viewer } from '@/viewer.js'
import LogoJustworks from '@/components/LogoJustworks.vue'
import PageLink from '@/components/PageLink.vue'
import useBreakpoint from '@/use/useBreakpoint.js'
import IconList from '~icons/ph/list'
import HeaderAvatarMenu from './HeaderAvatarMenu.vue'
import HeaderHelpMenu from './HeaderHelpMenu.vue'
import PEOMainNav from './PEOMainNav.vue'

const mainNavOpen = ref(false)

const { mobile, tablet } = useBreakpoint()
</script>

<style scoped>
header {
  max-width: 1184px;
  height: 66px;
  padding: 10px 3%;
  font-size: var(--text-sm);
}

header .header-row {
  align-items: center;
  height: 100%;
}

header :deep(.nav-drawer) {
  background-color: var(--peo-color-nav-background);
  z-index: 2;
  left: 0;
  transform: translateX(-100%);
  max-width: var(--peo-nav-mobile-width);
}

header :deep(.nav-drawer.open) {
  transform: translateX(0);
}
</style>
